<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button variant="danger" size="sm" v-b-modal.modal-delete-permission>
          <BIconX/> Delete
        </b-button>
        <b-modal id="modal-delete-permission" title="Delete permission" hide-footer>
          Are you sure that you want to delete this permission?<br>
          <b-button class="mt-3" variant="danger" size="sm" @click="deletePermission()">Yes, delete permission!</b-button>
        </b-modal>
      </template>
    </PageMenu>

    <div class="page-with-menu">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Details" active>
            <b-card-text>
              <FormTemplate v-if="form" :form="form">
                  <b-form-group class="label-for-xl" label="Placeholder" label-for="inputPlaceholder">
                      <b-form-input
                          type="text" id="inputPlaceholder" class="form-control form-control-xl"
                          placeholder="enter the name"
                          v-model="form.data.placeholder"
                          :state="form.states.placeholder"
                      />
                      <b-form-invalid-feedback v-if="form.errors">{{ form.errors.placeholder }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group class="label-for-xl" label="Description" label-for="inputDescription">
                      <b-form-textarea
                          id="inputDescription" class="form-control form-control-xl"
                          placeholder="enter the description"
                          v-model="form.data.description"
                          :state="form.states.description"
                      />
                      <b-form-invalid-feedback v-if="form.errors">{{ form.errors.description }}</b-form-invalid-feedback>
                  </b-form-group>
              </FormTemplate>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import PageMenu from '@/common/components/PageMenu';
import {BIconX} from 'bootstrap-vue';
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';

export default {
    components: {
      PageMenu,
      BIconX,
      FormTemplate
    },
    data(){
      return {
        page: {
            title: "Edit Permission",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Users",
                url: this.$router.resolve({name: "users"}).href
            },
            {
                title: "Permissions",
                url: this.$router.resolve({name: "users.permissions"}).href
            },
            {
                title: "Edit Permission",
            }
        ],
        form: null,
        permission: null
      }
    },
    methods: {
      deletePermission(){
        this.$api.delete(`permission/${this.permission.id}`).then(() => {
          this.$router.push({name: "users.permissions"});
        })
      },
      getPermission(){
        return this.$api.get(`permission/${this.$route.params.permissionId}`).then(({data}) => {
          this.permission = data;

          this.page.title = this.permission.placeholder;
        });
      },

    },
    mounted(){
      Promise.all([
        this.getPermission()
      ])
      .then(() => {
        this.form = new Form({description: this.permission.description || "", placeholder: this.permission.placeholder || ""},{
          onSubmit: (form) => {
            return this.$api.put(`permission/${this.permission.id}`,{
              data: {description: form.get('description'),placeholder: form.get('placeholder')}
            }).then((res) => {
              this.permission = res.data
              return res;
            });
          }
        });
      });
      this.$emit('set-sidebar',{active: "users"});
    },
};
</script>
